<!--<div class='Settle'></div>-->
<!--    市场分润报表-->
<template>
    <div class="big">
        <div v-if="show" :key="1" class="Settle" style="background-color: #f2f2f2; margin-top: 8px">
            <el-card shadow="never" style="margin-top: 8px">
                <el-form
                    @submit.native.prevent
                    :inline="true"
                    style="text-align: left"
                    v-model="form"
                    size="small"
                    label-width="80px"
                >
                    <el-row>
                        <el-col :span="8">
                            <el-form-item label="机构组名称" label-width="95px">
                                <el-select v-model="form.deptGroupCode" @change="handleChangeGroup" filterable>
                                    <el-option value="" label="请选择" />
                                    <el-option
                                        v-for="group in meta.groups"
                                        :value="group.code"
                                        :key="group.code"
                                        :label="group.name"
                                    />
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="机构名称">
                                <el-select v-model="form.deptCode" filterable>
                                    <el-option value="" label="请选择" />
                                    <el-option
                                        v-for="dept in meta.deptCodes"
                                        :label="dept.name"
                                        :key="dept.code"
                                        :value="dept.code"
                                    />
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="事件类型">
                                <el-select placeholder="请选择" v-model="form.changeType">
                                    <el-option value="" label="请选择" />
                                    <el-option
                                        v-for="e in meta.changeType"
                                        :label="e.label"
                                        :value="e.code"
                                        :key="e.code"
                                    />
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="业务类型">
                                <el-select placeholder="请选择" v-model="form.bizType">
                                    <el-option value="" label="请选择" />
                                    <el-option
                                        v-for="e in meta.bizType"
                                        :label="e.label"
                                        :value="e.code"
                                        :key="e.code"
                                    />
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="商品名称">
                                <el-input placeholder="请输入商品名称" v-model="form.goodsName" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="业务单号">
                                <el-input placeholder="订单号/退款单号/补货单号" v-model="form.bizNumber" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="8">
                            <ef-start-date v-model="form.startTimeStr" />
                        </el-col>
                        <el-col :span="8">
                            <ef-end-date v-model="form.endTimeStr" />
                        </el-col>
                    </el-row>
                </el-form>
            </el-card>
            <el-card shadow="never" style="margin-top: 8px; text-align: left">
                <el-button
                    type="primary"
                    @click="handleQuery(true)"
                    size="small"
                    v-if="hasPrivilege('menu.mall.manage.online.sell.count.change.open')"
                >
                    查询
                </el-button>
                <!--              报表明细导出-->
                <el-button
                    type="primary"
                    @click="detailedhandleExport"
                    size="small"
                    v-if="hasPrivilege('menu.mall.manage.online.sell.count.change.export')"
                >
                    导出
                </el-button>
                <el-button type="primary" @click="detailedempty" size="small"> 清空 </el-button>
                <el-button type="primary" @click="showCheckTableShowColumnDialog" size="small"> 表头 </el-button>
                <span style="float: right"
                    >当前条件借出商品总数{{ sellGoodsCountSum }}个，当前条件归还商品总数{{ repayGoodsCountSum }}个</span
                >
            </el-card>
            <!--表格部分-->
            <el-card shadow="never" style="margin-top: 8px">
                <el-table
                    id="settleTable"
                    ref="settleTable"
                    border
                    stripe
                    style="width: 100%"
                    :data="tableData"
                    size="mini"
                    :highlight-current-row="true"
                    max-height="450"
                >
                    <!--                    <el-table-column label="序号" type="index" fixed="left" :index="indexMethod"> </el-table-column>-->
                    <el-table-column
                        align="center"
                        label="门店名称"
                        width="200"
                        prop="deptName"
                        sortable
                        key="deptName"
                        v-if="showColumn('deptName')"
                    ></el-table-column>
                    <el-table-column
                        align="center"
                        label="事件类型"
                        width="200"
                        prop="changeType"
                        key="changeType"
                        v-if="showColumn('changeType')"
                    >
                        <template slot-scope="scope">
                            <p v-if="scope.row.changeType == -1">归还</p>
                            <p v-if="scope.row.changeType == 1">借出</p>
                        </template>
                    </el-table-column>
                    <el-table-column
                        align="center"
                        label="发生时间"
                        width="200"
                        prop="bizTriggerTime"
                        key="bizTriggerTime"
                        v-if="showColumn('bizTriggerTime')"
                    ></el-table-column>
                    <el-table-column
                        align="center"
                        label="商品名称"
                        width="200"
                        prop="goodsName"
                        key="goodsName"
                        v-if="showColumn('goodsName')"
                    ></el-table-column>
                    <el-table-column
                        align="center"
                        label="商品条码"
                        width="200"
                        prop="bar"
                        key="bar"
                        v-if="showColumn('bar')"
                    ></el-table-column>
                    <el-table-column
                        align="center"
                        label="借出商品数量"
                        width="200"
                        prop="goodsCount"
                        key="goodsCount"
                        v-if="showColumn('goodsCount')"
                    ></el-table-column>
                    <el-table-column
                        align="center"
                        label="业务单号"
                        width="200"
                        prop="bizNumber"
                        key="bizNumber"
                        v-if="showColumn('bizNumber')"
                    ></el-table-column>
                    <el-table-column
                        align="center"
                        label="业务类型"
                        width="200"
                        prop="bizType"
                        key="bizType"
                        v-if="showColumn('bizType')"
                    >
                        <template slot-scope="scope">
                            <p v-if="scope.row.bizType === 331">门店发货</p>
                            <p v-if="scope.row.bizType === 332">门店自提</p>
                            <p v-if="scope.row.bizType === 341">门店接单发货后退货</p>
                            <p v-if="scope.row.bizType === 342">门店自提后退货</p>
                            <p v-if="scope.row.bizType === 351">门店补货</p>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                    :page-sizes="page.pageSizes"
                    :page-size="form.limit"
                    :layout="page.PageStyle"
                    :total="page.total"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    @prev-click="handlePrevClick"
                    @next-click="handleNextClick"
                />
            </el-card>
            <check-table-show-column-dialog
                ref="checkTableShowColumnDialog"
                @checkTableShowColumn="setShowTableColumns"
            />
        </div>
    </div>
</template>

<script>
import Util from 'js/Util';
import UrlUtils from 'js/UrlUtils';
import EfReviewBiz from 'components/EfReviewBiz';
import EfStartDate from 'components/EfStartDate';
import EfEndDate from 'components/EfEndDate';
import EfDeptTypeAndDeptSelect from 'components/EfDeptTypeAndDeptSelect';
import CheckTableShowColumn from 'components/mixins/CheckTableShowColumn';
import CheckTableShowColumnDialog from 'components/CheckTableShowColumnDialog';
import { get } from 'request/http';
// eslint-disable-next-line vue/require-name-property
export default {
    // eslint-disable-next-line vue/no-unused-components
    components: { EfEndDate, EfStartDate, EfDeptTypeAndDeptSelect, EfReviewBiz, CheckTableShowColumnDialog, get },
    mixins: [CheckTableShowColumn],
    data() {
        return {
            // 报表明细
            form: {
                deptGroupCode: null, //机构组code
                deptCode: null, //机构code
                changeType: null, //变更类型
                bizType: null, //业务类型
                goodsName: null, //商品名称
                bizNumber: null, //业务单号
                startTimeStr: null, //开始时间
                endTimeStr: null, //结束时间
                page: 1,
                limit: 20,
            },
            page: {
                total: 0,
                pageSizes: Util.PageSizes,
                PageStyle: Util.PageStyle,
            },
            sellGoodsCountSum: 0, //借出
            repayGoodsCountSum: 0, //归还
            loading: false,
            tableData: [],
            totalTableData: [],
            current: {
                typeIdx: 3,
            },
            meta: {
                groups: [],
                deptCodes: [],
                cards: [],
                changeType: [
                    { code: -1, label: '归还' },
                    { code: 1, label: '借出' },
                ],
                bizType: [
                    { code: 331, label: '门店发货' },
                    { code: 332, label: '门店自提' },
                    { code: 341, label: '门店接单发货后退货' },
                    { code: 342, label: '门店自提后退货' },
                    { code: 351, label: '门店补货' },
                ],
                showReview: false,
            },
            Payment: {
                remitMoney: '',
                codes: [],
            },
            tableRef: 'settleTable',
            inCostDiffSummaryMoreType: false,
            inCostDiffSummaryMoreFrom: [],
            show: true,
            depts: [],
            index: 0,
            recommenderOrOrderEmployee: '省',
            recommenderOrOrdercity: '市',
            //全选框
            dialogFormVisible: false,
            checkAll: false,
            isIndeterminate: true,
        };
    },
    mounted() {
        this.handleQuery();
        UrlUtils.DeptGroup(this, (rst) => {
            this.meta.groups = rst || [];
        });
    },
    watch: {},
    methods: {
        handleChangeGroup() {
            const _this = this;
            _this.form.deptCode = '';
            UrlUtils.QueryRemote(
                this,
                '/system/dept/deptTree' + '?deptGroupCode=' + _this.form.deptGroupCode,
                (rst) => {
                    _this.meta.deptCodes = rst;
                }
            );
        },
        //明细清空
        detailedempty() {
            this.form.deptGroupCode = null; //机构组code
            this.form.deptCode = null; //机构code
            this.form.changeType = null; //变更类型
            this.form.bizType = null; //业务类型
            this.form.goodsName = null; //商品名称
            this.form.bizNumber = null; //业务单号
        },
        //市场明细查询
        handleQuery(type) {
            const _this = this;
            const _params = { params: _this.form };
            this.$http.get('/onlineShopGoodsSellCountChange/pageList', _params).then((rst) => {
                this.tableData = rst.data.data.list;
                this.page.total = rst.data.data.count;
                this.sellGoodsCountSum = rst.data.data.sellGoodsCountSum;
                this.repayGoodsCountSum = rst.data.data.repayGoodsCountSum;
            });
        },
        //报表明细导出
        detailedhandleExport() {
            const _this = this;
            UrlUtils.Export(_this, '商品借出/还货记录表', '/onlineShopGoodsSellCountChange/exportData', _this.form);
        },
    },
};
</script>

<style scoped>
.Settle .el-form-item {
    margin-bottom: 0;
}
</style>
<style>
.greenClass :hover {
    cursor: pointer !important;
}
</style>
